<template>
  <div id="preview">
    <div id="preview-app"></div>
  </div>
</template>

<script>
export default {
  name: 'Constructor2Preview',

  data () {
    return {
      baseUrl: process.env.VUE_APP_CONSTRUCTOR_2_BASE_URL
    }
  },

  computed: {
    epackage () {
      return {
        epackId: this.$route.query.epackId,
        epackType: this.$route.query.epackType,
        activeTemplate: this.$route.query.activeTemplate,
        activeLocale: this.$route.query.activeLocale,
        productName: this.$route.query.productName,
      }
    }
  },

  title () {
    return `24stream preview${this.epackage.productName ? ` | ${this.epackage.productName}` : ''}`
  },

  mounted () {
    const scripts = [document.createElement('script'), document.createElement('script')]
    scripts[0].innerHTML = `
        (function (w, d, s, o)  {
          var f = d.getElementsByTagName(s)[0];
          var j = d.createElement(s);
          w.TTLStreamReady = new Promise((resolve) =>  {
            j.async = true;
            j.src = '${process.env.VUE_APP_CONSTRUCTOR_2_BASE_URL}/widget.js';
            f.parentNode.insertBefore(j, f);
            j.onload = function ()  {
              w.ttlStream = new TTLStream(o);
              resolve(w.ttlStream);
            };
          });
        })(window, document, 'script',  { });
    `
    scripts[1].innerHTML = `
      TTLStreamReady.then(() =>  {
        ttlStream.findAndInsert( {
          el: '#preview-app',
          productId: '${this.epackage.epackId}',
          templateType: '${this.epackage.activeTemplate}',
          resultType: 'html',
          contentType: '${this.epackage.epackType}',
          language: '${this.epackage.activeLocale}',
          preview: true,
        });
      });
    `
    scripts.forEach(script => { this.$el.appendChild(script); })
  }
}
</script>
